import { cva } from "class-variance-authority"

export const minWidthVariants = cva([""], {
  variants: {
    /* Minimum width a popover can have. */
    size: {
      small: "min-w-popover-small",
      medium: "min-w-popover-medium",
      large: "min-w-popover-large",
    },
  },
})
