"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconPlus } from "@supernovaio/icons"

import { DMMenuItem, DMSkeleton, useDMMenuState } from "../../index"
import {
  Command as CommandPrimitive,
  useCommandState,
} from "../../libraries/cmdk"

const DMMenuListContext = React.createContext<true | undefined>(undefined)
const useIsInDMMenuList = () => React.useContext(DMMenuListContext)

const DMMenuList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List> & {
    /**
     * @description Whether the list should be scrollable or not. Defaults to `true`. If set to `false`, the list will stretch to the height of all it's elements. Use `false` if you know the height will fit into screen.
     */
    isScrollable?: boolean
  }
>(({ className, isScrollable = true, ...props }, ref) => (
  <DMMenuListContext.Provider value>
    <CommandPrimitive.List
      ref={ref}
      className={cn(
        "gap-nav-items flex flex-col overflow-y-auto overflow-x-hidden p-4 scrollbar-fancy motion-safe:scroll-smooth",
        // Workaround to make sure list can be scrolled properly when it is placed in a submenu which is constrained vertically.
        // 8px - spacing between the submenu and viewport edge, 36px - height of the search input.
        // TODO: rework this hack
        isScrollable
          ? "max-h-[calc(var(--radix-popper-available-height)-8px-36px)]"
          : "",
        className
      )}
      {...props}
    />
  </DMMenuListContext.Provider>
))

DMMenuList.displayName = CommandPrimitive.List.displayName

const DMMenu = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, children, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      "flex h-full w-full flex-col overflow-hidden",
      "focus:outline-none",
      className
    )}
    {...props}
  >
    {children}
  </CommandPrimitive>
))

const DMMenuLoading = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Loading>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Loading>
>(({ ...props }, ref) => (
  <CommandPrimitive.Loading ref={ref} {...props}>
    <div className="text-body text-neutral flex flex-col items-center justify-center gap-[1px] p-4">
      <DMSkeleton height={32} width="100%" />
      <DMSkeleton height={32} width="100%" />
      <DMSkeleton height={32} width="100%" />
      <DMSkeleton height={32} width="100%" />
      <DMSkeleton height={32} width="100%" />
      <DMSkeleton height={32} width="100%" />
      <DMSkeleton height={32} width="100%" />
      <DMSkeleton height={32} width="100%" />
      <DMSkeleton height={32} width="100%" />
    </div>
  </CommandPrimitive.Loading>
))

DMMenuLoading.displayName = CommandPrimitive.Loading.displayName
DMMenu.displayName = CommandPrimitive.displayName

const DMMenuSearchInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    endSlot?: React.ReactNode
  }
>(
  (
    {
      className,
      endSlot,
      autoFocus = true,
      placeholder = "Search...",
      ...props
    },
    ref
  ) => (
    <div
      className="border-b-neutral-faded flex flex-row border-b bg-white"
      // eslint-disable-next-line react/no-unknown-property
      cmdk-input-wrapper=""
    >
      <CommandPrimitive.Input
        ref={ref}
        autoFocus={autoFocus}
        className={cn(
          "text-body-small text-neutral placeholder:text-neutral-placeholder h-[36px] w-full px-12 py-4 outline-none",
          className
        )}
        placeholder={placeholder}
        {...props}
      />
      {endSlot && (
        <div className="flex items-center pr-4 align-middle">{endSlot}</div>
      )}
    </div>
  )
)

DMMenuSearchInput.displayName = CommandPrimitive.Input.displayName

const DMMenuEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="text-neutral-faded text-body flex min-h-[32px] rounded p-8"
    {...props}
  />
))

DMMenuEmpty.displayName = CommandPrimitive.Empty.displayName

const DMMenuDivider = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn("bg-border-neutral-faded -mx-1 h-[1px]", className)}
    {...props}
  />
))

DMMenuDivider.displayName = CommandPrimitive.Separator.displayName

function DMMenuShortcut({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn(
        "text-neutral-faded text-body-small ml-auto font-semibold tracking-widest",
        className
      )}
      {...props}
    />
  )
}

DMMenuShortcut.displayName = "DMMenuShortcut"

export interface DMMenuCreateItemProps {
  /** Label which appears before the current search query */
  prefix: string
  /** Callback to determine if there is an item which matches current search query */
  checkExactMatch: (search: string) => boolean
  /** Callback to create a new item. */
  onCreate: (search: string) => void
}

const DMMenuCreateItem = React.forwardRef<
  React.ElementRef<typeof DMMenuItem>,
  DMMenuCreateItemProps
>(({ prefix, checkExactMatch, onCreate, ...props }, ref) => {
  const search = useDMMenuState((state) => state.search)

  let label = `${prefix}`
  if (search) {
    label += `: ${search}`
  }

  return (
    <DMMenuItem
      ref={ref}
      forceMount
      isAction
      icon={IconPlus}
      {...props}
      onSelect={() => onCreate(search)}
    >
      {label}
    </DMMenuItem>
  )
})

DMMenuCreateItem.displayName = "DMMenuCreateItem"

export {
  DMMenu,
  DMMenuSearchInput,
  DMMenuList,
  DMMenuEmpty,
  DMMenuShortcut,
  DMMenuDivider,
  DMMenuLoading,
  DMMenuCreateItem,
  useCommandState as useDMMenuState,
  useIsInDMMenuList,
}
