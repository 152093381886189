import * as React from "react"
import { SVGProps } from "react"

export const IconInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M8 6h.007M8 8v2.667M2 8a6 6 0 1 0 12 0A6 6 0 0 0 2 8"
    />
  </svg>
)
