"use client"

import * as React from "react"

import {
  DMCheckBox,
  DMSystemTooltipProps,
  DMTooltip,
  highlightSearchText,
  normalizeTestId,
} from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconCheck } from "@supernovaio/icons"
import { IconType } from "@supernovaio/icons/types"

import { Command as CommandPrimitive } from "../../../libraries/cmdk"
import { DMIcon } from "../../DMIcon"
import { useDMMenuState, useIsInDMMenuList } from "../DMMenu"
import {
  getIndentLevelClassname,
  getLineClampClassname,
  SupportedLineClamp,
} from "../utils"

export interface DMMenuItemProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>,
    "disabled" | "children"
  > {
  /** Label to display in the menu item */
  children: string
  /** Description to display below the label */
  description?: string
  /** Icon to display on the left side of the menu item */
  icon?: IconType
  /** Disables icon slot
   *  @deprecated This will get removed, use `isAction` instead, it will hide the checkmark
   */
  disableIcon?: boolean
  /** Color of the icon
   *  @deprecated This will get removed.
   */
  iconColor?: React.ComponentPropsWithoutRef<typeof DMIcon>["color"]
  /** Custom content that appears on the right side of the menu item */
  endSlot?: React.ReactNode
  /** Custom content that appears between the icon and the label */
  startSlot?: React.ReactNode
  /** Custom content that appears after the label */
  labelEndSlot?: React.ReactNode
  /** Sets the indentation level of the item. */
  indentLevel?: number
  /** Whether this item is currently selected. */
  isSelected?: boolean
  /** Show checkbox with selected/unselected status instead of simple check */
  isMultiSelect?: boolean
  /** Whether this item is an action and cannot be selected. */
  isAction?: boolean
  /** Whether this item is currently disabled. */
  isDisabled?: boolean
  /** Sets how many lines of description should be displayed before it gets truncated. */
  maxDescriptionLines?: SupportedLineClamp
  /** Highlights search value in a label */
  highlightSearch?: boolean
  /** Configures tooltip for the item. */
  tooltipProps?: DMSystemTooltipProps
  /** Whether this item is destructive. */
  isDestructive?: boolean
  /** Test id, should be unique, normalized to use only alphanumeric characters and underscores */
  dataTestId?: string
}

type SelectedCheckmarkProps = {
  isSelected?: boolean
  isMultiSelect?: boolean
}

function SelectedCheckmark({
  isSelected,
  isMultiSelect,
}: SelectedCheckmarkProps) {
  if (!isMultiSelect) {
    return isSelected ? (
      <DMIcon svg={IconCheck} className="pointer-events-none" />
    ) : null
  }

  return <DMCheckBox className="pointer-events-none" checked={isSelected} />
}

const DMMenuItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  DMMenuItemProps
>(
  (
    {
      description,
      icon,
      disableIcon,
      iconColor = undefined,
      endSlot,
      labelEndSlot,
      indentLevel = 0,
      startSlot,
      isSelected,
      isAction = false,
      isDisabled,
      isDestructive,
      maxDescriptionLines = 1,
      className,
      isMultiSelect,
      highlightSearch = false,
      children,
      tooltipProps,
      dataTestId,
      ...props
    },
    ref
  ) => {
    const search = useDMMenuState((state) => state.search)
    const isInDMMenuItemList = useIsInDMMenuList()

    if (!isInDMMenuItemList) {
      throw new Error("DMMenuItem should be used inside of DMMenuList")
    }

    const normalizedDataTestId = normalizeTestId(dataTestId)

    const content = (
      <span
        className={cn(
          "flex h-full w-full gap-8 px-8 py-[6px]",
          !description && "items-center"
        )}
      >
        {!disableIcon && (
          <div
            className={cn(
              "flex shrink-0 items-center gap-4",
              description && "h-[20px]"
            )}
          >
            {!isAction && (
              <div className="w-icon-small h-icon-small">
                <SelectedCheckmark
                  isMultiSelect={isMultiSelect}
                  isSelected={isSelected}
                />
              </div>
            )}
            {icon && (
              <div className={getIndentLevelClassname(indentLevel)}>
                <DMIcon
                  color={iconColor}
                  svg={icon}
                  className="pointer-events-none"
                />
              </div>
            )}
          </div>
        )}

        {startSlot && <div className="shrink-0">{startSlot}</div>}
        <div className="flex flex-grow items-center gap-8">
          <div className="flex flex-col gap-2">
            <div
              className={cn(
                "text-body line-clamp-1 font-semibold",
                "flex flex-row gap-4",
                !icon && getIndentLevelClassname(indentLevel)
              )}
            >
              {highlightSearch
                ? highlightSearchText(children, search)
                : children}
              {labelEndSlot && <div className="shrink-0">{labelEndSlot}</div>}
            </div>
            {description && (
              <div
                className={cn(
                  "text-neutral-faded text-body-small font-semibold",
                  getLineClampClassname(maxDescriptionLines)
                )}
              >
                {description}
              </div>
            )}
          </div>
        </div>
        {endSlot && <div className="shrink-0">{endSlot}</div>}
      </span>
    )

    return (
      <CommandPrimitive.Item
        ref={ref}
        className={cn(
          "text-body data-[disabled]:opacity-disabled group relative min-h-[32px] cursor-pointer select-none rounded outline-none transition-colors",
          isDestructive
            ? "data-[selected]:bg-critical-faded data-[selected]:text-critical"
            : "data-[selected]:bg-neutral data-[selected]:text-on-neutral",
          isDisabled && "opacity-disabled cursor-not-allowed",
          className
        )}
        data-test-id={normalizedDataTestId}
        disabled={isDisabled}
        {...props}
      >
        {tooltipProps ? (
          <DMTooltip {...tooltipProps}>{content}</DMTooltip>
        ) : (
          content
        )}
      </CommandPrimitive.Item>
    )
  }
)

DMMenuItem.displayName = CommandPrimitive.Item.displayName

export { DMMenuItem }
