"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as PopoverPrimitive from "@radix-ui/react-popover"

import { minWidthVariants } from "../../styling/popovers"

import { VariantProps } from "class-variance-authority"

const DMPopover = PopoverPrimitive.Root

const DMPopoverTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>(({ onClick, ...props }, ref) => (
  <PopoverPrimitive.Trigger
    asChild
    onClick={(e) => {
      e.stopPropagation()
      onClick?.(e)
    }}
    {...props}
    ref={ref}
  />
))

DMPopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName

const DMPopoverArrow = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow>
>(({ className, ...props }, ref) => (
  <span className="shadow-overlay">
    <PopoverPrimitive.Arrow
      {...props}
      className={cn(
        "fill-[--rs-color-background-elevation-overlay]",
        className
      )}
      ref={ref}
    />
  </span>
))

DMPopoverArrow.displayName = PopoverPrimitive.Arrow.displayName

const DMPopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> &
    VariantProps<typeof minWidthVariants>
>(
  (
    {
      className,
      align = "start",
      sideOffset = 4,
      onClick,
      size = "small",
      ...props
    },
    ref
  ) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        className={cn(
          "rounded-medium border-neutral-faded bg-elevation-overlay text-on-neutral shadow-overlay z-40 overflow-hidden border outline-none",
          "w-[var(--radix-popper-anchor-width)]",
          "animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          minWidthVariants({ size }),
          className
        )}
        sideOffset={sideOffset}
        onClick={(e) => {
          e.stopPropagation()
          onClick?.(e)
        }}
        {...props}
      />
    </PopoverPrimitive.Portal>
  )
)

DMPopoverContent.displayName = PopoverPrimitive.Content.displayName

const DMPopoverAnchor = PopoverPrimitive.Anchor
DMPopoverAnchor.displayName = PopoverPrimitive.Anchor.displayName

export {
  DMPopover,
  DMPopoverArrow,
  DMPopoverTrigger,
  DMPopoverAnchor,
  DMPopoverContent,
}
