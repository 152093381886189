import React from "react"

export function highlightText(
  text: string,
  highlight: string | string[] | false,
  renderHighlight: (text: string, index: number) => React.ReactNode
) {
  if (!highlight) {
    return [text]
  }

  // Create the regex based on the type of 'highlight'
  let regex: RegExp

  if (Array.isArray(highlight)) {
    const escapedHighlights = highlight.map((h) =>
      h.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    )

    regex = new RegExp(`(${escapedHighlights.join("|")})`, "gi")
  } else {
    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")

    regex = new RegExp(`(${escapedHighlight})`, "gi")
  }

  // Split and highlight text
  const parts = text.split(regex)

  return parts.map((part, index) =>
    regex.test(part) ? renderHighlight(part, index) : part
  )
}

export function highlightSearchText(
  text: string,
  highlight: string | string[] | false
) {
  return highlightText(text, highlight, (part, index) => (
    <mark key={index} className="bg-primary-faded text-primary">
      {part}
    </mark>
  ))
}
