"use client"

import * as React from "react"

import { DMIcon, DMSkeleton } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconChevronDown } from "@supernovaio/icons"
import { IconType } from "@supernovaio/icons/types"

import { SegmentedInputChildModifier } from "../../DMSegmentedInputWrapper/DMSegmentedInputWrapper"

type DMSelectTriggerButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "disabled"
> & {
  children: React.ReactNode
  size?: "small" | "medium"
  startSlot?: React.ReactNode
  endSlot?: React.ReactNode
  isDisabled?: boolean
  icon?: IconType
  iconColor?: React.ComponentPropsWithoutRef<typeof DMIcon>["color"]
  isLoading?: boolean
  hasError?: boolean
}

const DMSelectTriggerButton = React.forwardRef<
  HTMLButtonElement,
  DMSelectTriggerButtonProps
>(
  (
    {
      className,
      children,
      size = "medium",
      startSlot,
      endSlot,
      isDisabled,
      icon,
      iconColor,
      isLoading,
      hasError,
      ...props
    },
    ref
  ) => {
    return (
      <DMSkeleton isLoading={isLoading ?? false}>
        <button
          ref={ref}
          className={cn(
            "border-neutral-faded bg-elevation-base h-form-medium text-body flex w-full min-w-[160px] items-center justify-between truncate rounded border px-8 transition-colors",
            "hover:border-neutral placeholder:text-neutral-placeholder ",
            "focus-visible:border-focus focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
            "disabled:opacity-disabled disabled:pointer-events-none disabled:cursor-not-allowed",
            "z-10",
            hasError && "border-critical hover:border-critical",
            SegmentedInputChildModifier,
            size === "small" && "h-form-small",
            className
          )}
          disabled={isDisabled}
          type="button"
          {...props}
        >
          <div className="flex min-w-0 items-center gap-8">
            {icon && <DMIcon color={iconColor} svg={icon} />}
            {startSlot}
            <div className="truncate">{children}</div>
          </div>
          <div className="flex shrink-0 items-center gap-8">
            {endSlot}
            <DMIcon size="medium" svg={IconChevronDown} />
          </div>
        </button>
      </DMSkeleton>
    )
  }
)

DMSelectTriggerButton.displayName = "DMSelectTriggerButton"

export { DMSelectTriggerButton }
