export type SupportedLineClamp = 1 | 2 | 3

export function getLineClampClassname(maxLines: SupportedLineClamp) {
  switch (maxLines) {
    case 1:
      return "line-clamp-1"
    case 2:
      return "line-clamp-2"
    case 3:
      return "line-clamp-3"
  }
}

export const getIndentLevelClassname = (level: number) => {
  switch (level) {
    case 0:
      return "pl-0"
    case 1:
      return "pl-8"
    case 2:
      return "pl-16"
    case 3:
      return "pl-24"
    case 4:
      return "pl-32"
    case 5:
      return "pl-40"
    case 6:
      return "pl-[48px]"
    default:
      return "pl-[56px]"
  }
}
